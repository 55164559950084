@import '../../styles/customMediaQueries.css';

.content {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  color: var(--colorGrey700);

  & p {
    color: var(--colorGrey700);
  }

  & a {
    color: var(--marketplaceBlue);
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
}
