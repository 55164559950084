.modalContent {
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.actionButton {
  min-height: 40px;
  max-width: 150px;
}

.qrWrapper {
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
}

@media screen and (max-width: 768px) {
  .actionButton {
    margin: 0 24px;
  }
}
